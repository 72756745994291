/** start Blocks **/
.block {

	&.block-regions {	
		ul {	
			li {
				width: 25%;
			}
		}
	}

}



/** regions **/
/*	.region {

		.region-pre-footer2 .block:first-child ul {
			width: 589.5px;
			li {
				width: 33%;
				display: inline-block;
			}
		}
	}*/
